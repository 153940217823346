import { useEffect, useRef, useState } from "react";
import { chain } from "lodash";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import getMobileDeviceData from "ismobilejs";

import styles from "./Chat.module.css";

import { usePostChatMessageMutation } from "../../app/chat.api";
import { MessageRoles, MessageTypes } from "./Chat.model";
import { useChatHistoryPolling } from "./Chat.hooks";
import { getUrlData } from "../../App.utils";

export const RespondentInput = () => {
  const [inputText, setInputText] = useState("");
  const [postChatMessageMutation] = usePostChatMessageMutation();
  const { chatMessages, interviewEnded } = useChatHistoryPolling();

  const inputRef = useRef();

  useEffect(() => {
    if (interviewEnded) {
      setInputText("");
      inputRef.current.querySelector("textarea").blur();
      return;
    }

    // in case it is embedded in another app it shouldn't steal focus from main app
    const isEmbedded = getUrlData().embedded === 'true'
    if (!isEmbedded) {
      inputRef.current.querySelector("textarea").focus();
    }
  }, [chatMessages, interviewEnded]);

  const [role, type] = chain(chatMessages).last().at(["role", "type"]).value();

  const isEmptyInput = inputText.trim().length === 0;
  const shouldDisableRespondentInput =
    role === MessageRoles.Respondent ||
    type === MessageTypes.TypingProgress ||
    interviewEnded ||
    isEmptyInput;

  const handleInputChange = (e) => {
    const message = e.target.value;
    setInputText(message);
  };

  const handleSendMessage = () => {
    if (isEmptyInput) {
      return;
    }
    setInputText("");
    postChatMessageMutation({
      previousFDChatMessageDataId: chain(chatMessages)
        .last()
        .get("fdMessageDataId")
        .value(),
      content: inputText,
    });
  };

  const handleEnterPress = (e) => {
    const isEnterPressed = e.key === "Enter";
    const isMobile = getMobileDeviceData(window.navigator);
    if (!isEnterPressed) {
      // if not Enter pressed do nothing, standard onChange behaviour will be used when any other key is pressed
      return;
    } else if (isMobile.phone || isMobile.tablet) {
      // for mobile devices virtual keyboard will be used and Enter will be handled with default means adding new line to the input; do nothing with it
      return;
    } else if (e.shiftKey) {
      // for desktop use default behaviour in case Shift key is pressed
      return;
    } else {
      // for desktop do not use default behaviour but send message on Enter press
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Input
      ref={inputRef}
      className={styles.input}
      fullWidth
      multiline
      disableUnderline
      value={inputText}
      disabled={interviewEnded}
      onChange={handleInputChange}
      onKeyPress={handleEnterPress}
      inputProps={{
        autocorrect: "off",
      }}
      endAdornment={
        <IconButton
          disabled={shouldDisableRespondentInput}
          className={styles['send-button']}
          onClick={handleSendMessage}
        >
          <SendIcon fontSize="large" />
        </IconButton>
      }
      maxRows={3}
      placeholder="Start typing your message..."
    />
  );
};
