import styles from "./InfoPanel.module.css";

export function InfoPanel() {
  return (
    <aside className={styles["info-panel"]}>
      <section className={styles["interviewer-info"]}>
        <h1 className={styles["interviewer-info-description"]}>
          Interview with Sage AI
        </h1>
      </section>
    </aside>
  );
}
