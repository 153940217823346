import { useEffect, useRef } from "react";

import styles from "./Chat.module.css";

import { useChatHistoryPolling } from "./Chat.hooks";
import { ChatDialogMessage } from "./ChatDialogMessage";
import { MessageRoles } from "./Chat.model";

export function ChatDialog() {
  const chatDialogRef = useRef();
  const { chatMessages } = useChatHistoryPolling();

  useEffect(() => {
    chatDialogRef.current.scrollBy({ top: chatDialogRef.current.scrollHeight });
  }, [chatMessages]);

  return (
    <div className={styles["chat-dialog"]} ref={chatDialogRef}>
      <ul>
        {chatMessages.map(({ role, type, content }, index) => {
          const isInterviewerMessage = role !== MessageRoles.Respondent;
          const messageWrapperClassName = isInterviewerMessage
            ? styles["interviewer-message-wrapper"]
            : styles["respondent-message-wrapper"];
          return (
            <div
              key={index}
              className={`${styles["message-wrapper"]} ${messageWrapperClassName}`}
            >
              {isInterviewerMessage && (
                <div className={styles["interviewer-message-avatar"]}>
                  <p>S</p>
                </div>
              )}
              <ChatDialogMessage role={role} type={type} content={content} />
            </div>
          );
        })}
      </ul>
    </div>
  );
}
