import styles from "./TypingProgress.module.css";

export const TypingProgress = () => {
  return (
    <span className={styles["typing-progress"]}>
      <i>Sage AI is typing</i>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};
